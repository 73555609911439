import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Label, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { settings } from '../../settings';
import { editEmail, verifyNewEmail } from '@a1-ict/fsbo/userSlice/requests';
import { usePrivateFunction } from '../Security/usePrivateFunction';
import { ToastDismiss } from '../Common';
import { selectError } from '@a1-ict/core/dialogSlice';

const ChangeEmailModal = ({ show, setShowEmailChange }) => {
  const initialData = { new_email: '', pass: '', code: '' };
  const [step, setStep] = useState('newEmail');
  const [data, setData] = useState(initialData);

  const { secure } = usePrivateFunction();
  const verifyCodeError = useSelector((state) => selectError(state, 'verifyNewEmail_error'));
  const editEmailError = useSelector((state) => selectError(state, 'editEmail_error'));

  const dispatch = useDispatch();

  useEffect(() => {
    setData(initialData);
    setStep('newEmail');
  }, [show]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleEditRequest = () => {
    secure(async () => {
      const result = await dispatch(
        editEmail({ body: { email: data.new_email, password: data.pass } })
      );
      if (result?.payload?.ok) {
        setStep('code');
      }
    });
  };

  const handleCodeCheck = () => {
    secure(async () => {
      const result = await dispatch(verifyNewEmail({ dispatch, settings, code: data.code }));
      if (result?.payload?.ok) {
        setShowEmailChange(false);
      }
    });
  };

  return (
    <Modal isOpen={show} toggle={() => setShowEmailChange(!show)} size="lg">
      <ModalHeader toggle={() => setShowEmailChange(!show)}>Промяна на e-mail адрес</ModalHeader>
      <ModalBody className="p-0 d-flex flex-column justify-content-center">
        <Row className="justify-content-center">
          <Col xs={12} sm={7} className="my-3 d-flex flex-column justify-content-center">
            <ToastDismiss
              content={verifyCodeError}
              showToast={!!verifyCodeError}
              className="p-1 login-alert"
            />
            <ToastDismiss
              content={editEmailError}
              showToast={!!editEmailError}
              className="p-1 login-alert"
            />
          </Col>
        </Row>

        {step === 'newEmail' ? (
          <Row className="justify-content-center my-3">
            <Col xs={12} sm={7} className="mb-3 d-flex flex-column justify-content-center px-5">
              <div className=" d-flex flex-column justify-content-center mb-2">
                <Label for="new_email" className="text-truncate">
                  Нов e-mail адрес
                </Label>
                <Input
                  id="new_email"
                  title="Пример: office@fsbo.bg"
                  type="email"
                  name="new_email"
                  className="form-control-lg"
                  value={data.new_email}
                  onChange={handleInput}
                />
              </div>
              <div className=" d-flex flex-column justify-content-center mb-3">
                <Label for="pass" className="text-truncate">
                  Парола
                </Label>
                <Input
                  id="pass"
                  type="password"
                  name="pass"
                  className="form-control-lg"
                  value={data.pass}
                  onChange={handleInput}
                />
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn animated px-3 py-2" onClick={handleEditRequest}>
                  <span className="">Потвърдете</span>
                </button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="justify-content-center my-3">
            <Col xs={12} sm={7} className="mb-3 d-flex flex-column justify-content-center px-5">
              <div className=" d-flex flex-column justify-content-center mb-2">
                <Label for="code" className="text-truncate">
                  Въведете кода от получения e-mail
                </Label>
                <Input
                  id="code"
                  name="code"
                  className="form-control-lg"
                  value={data.code}
                  onChange={handleInput}
                />
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn animated px-3 py-2" onClick={handleCodeCheck}>
                  <span className="">Потвърдете</span>
                </button>
              </div>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ChangeEmailModal;
