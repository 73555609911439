import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { settings } from '../../settings';
import { deleteUser } from '@a1-ict/fsbo/userSlice/requests';
import { usePrivateFunction } from '../Security/usePrivateFunction';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

const DeleteUserModal = ({ show, setShowDelete }) => {
  const [pass, setPass] = useState('');

  const { secure } = usePrivateFunction();
  const dispatch = useDispatch();

  const handleInput = (e) => {
    const { value } = e.target;
    setPass(value);
  };

  const handleDeleteProfile = () => {
    secure(async () => {
      const result = await dispatch(deleteUser({ dispatch, settings, password: pass }));
      if (result.payload?.status === 204) {
        navigate('/logout');
      } else {
        toast.error(result.error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  };

  return (
    <Modal isOpen={show} toggle={() => setShowDelete(!show)} size="lg">
      <ModalHeader toggle={() => setShowDelete(!show)}>Изтриване на профила</ModalHeader>
      <ModalBody className="p-0 d-flex flex-column justify-content-center">
        <Row className="justify-content-center my-3">
          <Col xs={12} sm={7} className="mb-3 d-flex flex-column justify-content-center px-5">
            Наистина ли искате да изтриете профила си?
          </Col>
          <Col xs={12} sm={7} className="mb-3 d-flex flex-column justify-content-center px-5">
            <div className=" d-flex flex-column justify-content-center mb-3">
              <Label for="pass" className="text-truncate">
                Текуща парола на профила
              </Label>
              <Input
                id="pass"
                type="password"
                name="pass"
                className="form-control-lg"
                value={pass}
                onChange={handleInput}
              />
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button className="btn animated px-3 py-2" onClick={handleDeleteProfile}>
          <span className="">Изтрий</span>
        </button>
        <Button color="secondary" onClick={() => setShowDelete(false)}>
          Отказ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteUserModal;
