import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Label, Input } from 'reactstrap';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import PrivateRoute from '../../components/Security/privateRoute';
import { selectMyDetails, selectInvoiceDetails } from '@a1-ict/fsbo/userSlice/selectors';
import { editClubNumber, editName, fetchMyDetails, setClubNumber } from '@a1-ict/fsbo/userSlice/requests';
import {
  cyrillicRegex,
} from '../../data/validationsRegex';
import { toast } from 'react-toastify';
import ChangePhoneNumber from '../../components/MyProfile/ChangePhoneNumber';
import ChangeEmailModal from '../../components/MyProfile/ChangeEmailModal';
import DeleteUserModal from '../../components/MyProfile/DeleteUserModal';
import ChangeUserPassword from '../../components/MyProfile/ChangeUserPassword';
import VerifyEmailModal from '../../components/MyProfile/VerifyEmailModal';
import { resendCode } from '@a1-ict/core/authSlice';
import InvoiceDetails from '../../components/InvoiceDetails';
import a1Logo from "../../images/a1_logo.png"

const MyProfile = () => {
  const [profileData, setProfileData] = useState({});


  const [showNumberChange, setShowNumberChange] = useState(false);
  const [showEmailChange, setShowEmailChange] = useState(false);
  const [showEmailV, setShowEmailV] = useState(false);
  const [showDelete, setShowDelete] = useState(false);


  const dispatch = useDispatch();
  const myDetails = useSelector(selectMyDetails);

  const myInvoiceDetails = useSelector(selectInvoiceDetails);


  useEffect(() => {
    let copyOfDetails = { ...myDetails };
    copyOfDetails.phone_number = copyOfDetails?.phone_number?.replace(/^359/, '0');
    setProfileData(copyOfDetails);
  }, [myDetails]);


  const handleProfileDetails = (e) => {
    const { name, value } = e.target;
    let regex = /^[0-9]+$|^$/;

    if (e.target.getAttribute('data-type') && e.target.getAttribute('data-type') === 'number') {
      if (!value.match(regex)) {
        return;
      } else {
        return setProfileData({ ...profileData, [name]: Number(value) });
      }
    }

    setProfileData({ ...profileData, [name]: value });
  };

  const handleClubNumber = async () => {
    let response;
    if (myDetails.club_number)
      response = await dispatch(editClubNumber({ body: { club_number: profileData.club_number } }))
    else response = await dispatch(setClubNumber({ body: { club_number: profileData.club_number } }))

    if (!!response?.payload?.ok)
      toast.success('Успешно запазване на клубен номер!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    else toast.error(response.error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }


  const handleNamesEdit = async () => {
    if (!cyrillicRegex.test(profileData.full_name)) {
      toast.error('Името може да бъде само на кирилица', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    const response = await dispatch(editName({ body: { name: profileData.full_name } }));

    if (!!response?.payload?.ok)
      toast.success('Успешно запазване на данните!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  };

  const handleVerified = () => {
    dispatch(fetchMyDetails());
  };

  return (
    <PrivateRoute>
      <DashboardLayout title="Моят профил">
        <ChangePhoneNumber show={showNumberChange} setShowNewPhone={setShowNumberChange} />
        <ChangeEmailModal show={showEmailChange} setShowEmailChange={setShowEmailChange} />
        <VerifyEmailModal
          handleVerified={handleVerified}
          email={myDetails.email}
          show={showEmailV}
          setShow={setShowEmailV}
        />
        <DeleteUserModal show={showDelete} setShowDelete={setShowDelete} />

        {myDetails && (
          <Row className="bg-white p-2 p-sm-4 px-md-2 p-lg-4 profile">
            <Col xs={12} className="my-2">
              <h5 className="h5">Информация за връзка</h5>
            </Col>
            <Col xs={12} className="mb-3 ">
              <div className=" d-flex flex-column justify-content-start ">
                <Label for="full_name" className="text-truncate">
                  {myDetails.is_business ? 'Име на фирмата' : 'Име и фамилия'}
                </Label>
                <div className="d-flex">
                  <Input
                    id="full_name"
                    type="text"
                    name="full_name"
                    className="form-control-lg"
                    value={profileData.full_name ? profileData.full_name : ''}
                    onChange={handleProfileDetails}
                  />
                  <button className="btn animated px-4 py-2 ml-2" onClick={handleNamesEdit}>
                    <span className="font-weight-bold">Запази </span>
                  </button>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 ">
              <div className=" d-flex flex-column justify-content-start ">
                <Label for="email" className="text-truncate">
                  E-mail
                </Label>
                <Input
                  id="email"
                  type="email"
                  title="Пример: office@fsbo.bg"
                  name="email"
                  className="form-control-lg"
                  value={profileData.email ? profileData.email : ''}
                  onChange={handleProfileDetails}
                  readOnly
                />
                <div
                  className="color-default ml-1 mt-1 profile__change_detail"
                  onClick={() => setShowEmailChange(true)}
                >
                  Променете e-mail адрес
                </div>
                {!myDetails.email_verified && (
                  <div
                    className="color-default ml-1 mt-1 profile__change_detail"
                    onClick={async () => {
                      const result = await dispatch(resendCode({ username: myDetails.email }));

                      if (result?.payload?.ok) setShowEmailV(true);
                    }}
                  >
                    Верифицирайте e-mail
                  </div>
                )}
                {/* <FormText>(напр. "/ Месец")</FormText> */}
              </div>
            </Col>
            <Col xs={12} sm={6} className="mb-3 ">
              <div className=" d-flex flex-column justify-content-start ">
                <Label for="phone_number" className="text-truncate">
                  Телефон
                </Label>
                <Input
                  id="phone_number"
                  type="text"
                  name="phone_number"
                  className="form-control-lg"
                  value={profileData.phone_number ? profileData.phone_number : ''}
                  onChange={handleProfileDetails}
                  data-type="number"
                  readOnly
                />
                <div
                  className="color-default ml-1 mt-1 profile__change_detail"
                  onClick={() => setShowNumberChange(true)}
                >
                  Променете телефонен номер
                </div>
                {/* <FormText> (напр. "От")</FormText> */}
              </div>
            </Col>
          </Row>
        )}
        <Row className='profile bg-white p-2 p-sm-4 px-md-2 p-lg-4 mt-2'>    <Col xs={10} className="mb-3 ">
          <div className=" d-flex flex-column justify-content-start ">
            <div className="d-flex align-items-center mb-2">
              <img src={a1Logo} alt="a1 logo" width="40" height={40} className="mr-2" />
              <span className="text-truncate ">
                Клуб
              </span>
            </div>

            <div className="d-flex">
              <Input
                id="club_number"
                type="text"
                name="club_number"
                className="form-control-lg"
                value={profileData.club_number || ''}
                onChange={handleProfileDetails}
              />
              <button className="btn animated px-4 py-2 ml-2" onClick={handleClubNumber}>
                <span className="font-weight-bold">Запази </span>
              </button>
            </div>
          </div>
        </Col></Row>
        <Row className='profile bg-white p-2 p-sm-4 px-md-2 p-lg-4 mt-2'><Col><InvoiceDetails myInvoiceDetails={myInvoiceDetails} /></Col></Row>
        <ChangeUserPassword />
        <Row className="my-3">
          <Col xs={12} className="mt-2 d-flex align-items-end justify-content-end">
            <div
              className="color-default ml-1 mt-1 profile__change_detail"
              onClick={() => {
                setShowDelete(true);
              }}
            >
              Изтриване на профила
            </div>
          </Col>
        </Row>
      </DashboardLayout>
    </PrivateRoute>
  );
};

export default MyProfile;
