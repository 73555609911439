import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Label, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { settings } from '../../settings';
import { editPhone, resendNewPhoneCode, verifyNewPhone } from '@a1-ict/fsbo/userSlice/requests';
import { usePrivateFunction } from '../Security/usePrivateFunction';
import { selectError } from '@a1-ict/core/dialogSlice';
import { ToastDismiss } from '../Common';
import { toast } from 'react-toastify';

const ChangePhoneNumber = ({ show, setShowNewPhone }) => {
  const initialData = { newPhone: '', code: '' };
  const [newPhoneStep, setNewPhoneStep] = useState(true);
  const [data, setData] = useState(initialData);

  const { secure } = usePrivateFunction();
  const verifyPhoneError = useSelector((state) => selectError(state, 'verifyNewPhone_error'));
  const editPhoneError = useSelector((state) => selectError(state, 'editPhone_error'));

  const dispatch = useDispatch();

  const handleEditRequest = () => {
    secure(async () => {
      const result = await dispatch(editPhone({ body: { phone: data.newPhone } }));
      if (result?.payload?.ok) {
        setNewPhoneStep(false);
      }
    });
  };

  const handleResendPhoneCode = () => {
    secure(async () => {
      const result = await dispatch(resendNewPhoneCode());
      if (result?.payload?.ok)
        toast.success("Успешно изпратен код!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      else
        toast.error(result.error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    if (!show) {
      setNewPhoneStep(true);
      setData(initialData);
    }
  }, [show]);

  const handleCodeCheck = () => {
    secure(async () => {
      const result = await dispatch(verifyNewPhone({ dispatch, settings, code: data.code }));
      if (result?.payload?.ok) {
        setShowNewPhone(false);
        toast.success('Успешно сменихте телефонния си номер!');
      }
    });
  };

  return (
    <Modal isOpen={show} toggle={() => setShowNewPhone(!show)} size="lg">
      <ModalHeader toggle={() => setShowNewPhone(!show)}>Промяна на телефонен номер</ModalHeader>
      <ModalBody className="p-0 d-flex flex-column justify-content-center">
        <Row className="justify-content-center">
          <Col xs={12} sm={7} className="my-3 d-flex flex-column justify-content-center">
            <ToastDismiss
              content={verifyPhoneError}
              showToast={!!verifyPhoneError}
              className="p-1 login-alert"
            />
            <ToastDismiss
              content={editPhoneError}
              showToast={!!editPhoneError}
              className="p-1 login-alert"
            />
          </Col>
        </Row>
        {newPhoneStep ? (
          <Row className="justify-content-center my-3">
            <Col xs={6} className="mb-3 ">
              <div className=" d-flex flex-column justify-content-center mb-3">
                <Label for="newPhone" className="text-truncate">
                  Въведете нов номер
                </Label>
                <Input
                  id="newPhone"
                  type="text"
                  name="newPhone"
                  className="form-control-lg"
                  value={data.newPhone}
                  onChange={handleInput}
                />
              </div>
              <div className="d-flex justify-content-center">

                <button className="btn animated px-3 py-2" onClick={handleEditRequest}>
                  <span className="">Напред</span>
                </button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className="justify-content-center my-3">
            <Col xs={6} className="mb-3 ">
              <div className=" d-flex flex-column justify-content-center mb-3">
                <Label for="code" className="text-truncate">
                  Въведете кода от получения SMS
                </Label>
                <Input
                  id="code"
                  type="text"
                  name="code"
                  className="form-control-lg"
                  value={data.code}
                  onChange={handleInput}
                />
              </div>
              <div className="d-flex justify-content-between">
                <button className="btn animated px-3 py-2" onClick={handleResendPhoneCode}>
                  <span className="">Изпрати код отново</span>
                </button>
                <button className="btn animated px-3 py-2" onClick={handleCodeCheck}>
                  <span className="">Потвърдете код</span>
                </button>
              </div>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ChangePhoneNumber;
