import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Label, Form } from 'reactstrap';
import { settings } from '../../settings';
import { changePassword } from '@a1-ict/fsbo/userSlice/requests';
import { passwordValidationRegex } from '../../data/validationsRegex';
import { toast } from 'react-toastify';
import PasswordInput from '../Common/Inputs/PasswordInput';

const ChangeUserPassword = () => {
  const dispatch = useDispatch();

  const handleReset = useCallback(async (e) => {
    e.preventDefault();
    const { old_password, new_password, confirm_new_password } = e.target;

    if (!passwordValidationRegex.test(new_password.value)) {
      toast.error(
        'Паролата трябва да е минимум 6 знака, да съдържа комбинация от цифри, главни и малки латински букви',
        {
          position: toast.POSITION.BOTTOM_CENTER,
        }
      );
      return;
    }
    if (new_password.value !== confirm_new_password.value) {
      toast.error('Паролите не съвпадат', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    const result = await dispatch(
      changePassword({
        dispatch,
        settings,
        body: {
          old_password: old_password.value,
          new_password: new_password.value,
          confirm_new_password: confirm_new_password.value,
        },
      })
    );

    if (result?.payload?.ok) {
      toast.success('Успешно сменихте паролата си! ', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else
      toast.error('Грешна парола!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  }, []);

  return (
    <Form onSubmit={handleReset}>
      <Row className="bg-white p-2 p-sm-4 px-md-2 p-lg-4 profile mt-2">
        <Col xs={12} className="my-2">
          <h5 className="h5">Промяна на парола</h5>
        </Col>
        <Col xs={12} sm={12} className="mb-3 ">
          <Label for="old_password">Текуща парола</Label>
          <PasswordInput name="old_password" id="old_password" required="" />
        </Col>
        <Col xs={12} sm={6} className="mb-3 ">
          <Label for="new_password">Нова парола</Label>
          <PasswordInput name="new_password" id="new_password" required="" />
          <span className="animated text-small">
            Паролата трябва да е минимум 6 знака, да съдържа комбинация от цифри, главни и малки
            латински букви
          </span>
        </Col>
        <Col xs={12} sm={6} className="mb-3 ">
          <Label for="confirm_new_password">Повторете паролата</Label>
          <PasswordInput name="confirm_new_password" id="confirm_new_password" required="" />
        </Col>

        <Col xs={12}>
          <button className="btn animated px-4 py-2 ">
            <span className="font-weight-bold">Запази </span>
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangeUserPassword;
